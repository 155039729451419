import CashbackWeekly from '@/app/[lng]/(general)/promotion/[...slug]/components/CashbackWeekly';
import NewPayUserPage from '@/app/[lng]/(general)/promotion/[...slug]/components/NewPayUserPage';
import RebatePage from '@/app/[lng]/(general)/promotion/[...slug]/components/RebatePage';

export const promotionRouteEnum = {
  FIRST_DEPOSIT: 'PhomBet-promotion-100-first-deposit',
  REBATE: 'PhomBet-promotion-daily-rebate',
  CASHBACK_WEEKLY: 'PhomBet-promotion-cashback-weekly',
};

export const promotionsPage: any = {
  [promotionRouteEnum.FIRST_DEPOSIT]: {
    thumbnail: '/img/promotion/banner_1.png',
    title: 'promotionDepositTitleLower',
    description: 'betReachedRangeOnce',
    link: `/promotion/${promotionRouteEnum.FIRST_DEPOSIT}`,
    content: ({ params }: any) => <NewPayUserPage params={params} />,
  },
  [promotionRouteEnum.REBATE]: {
    thumbnail: '/img/promotion/rebate-banner.png',
    title: 'rebateTitle',
    description: 'betReachedRangeOnce',
    link: `/promotion/${promotionRouteEnum.REBATE}`,
    content: ({ params }: any) => <RebatePage params={params} />,
  },
  [promotionRouteEnum.CASHBACK_WEEKLY]: {
    title: 'cashbackWeeklyTitle',
    description: 'cashbackWeeklyDes',
    thumbnail: '/img/promotion/cashback-weekly.png',
    link: `/promotion/${promotionRouteEnum.CASHBACK_WEEKLY}`,
    content: ({ params }: any) => <CashbackWeekly params={params} />,
  },
};
