import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import ButtonCore from '@/components/core/Button';
import Link from '@/components/core/Link';

import { useTranslation } from '@/app/i18n';

const CashbackWeekly: React.FC<any> = async ({ params }) => {
  // const [loaded, setLoaded] = useState(false);
  const { t } = await useTranslation(params?.lng, 'PromotionPage');

  return (
    <div className='md:pr-6'>
      <div className='p-3 md:hidden'></div>
      <div className='px-3 md:rounded-none md:md:p-6 md:px-0 md:pb-0'>
        <Image
          width={1000}
          height={430}
          src='/img/promotion/cashback-weekly.png'
          className={clsx(
            'w-full rounded-xl md:rounded-lg'
            // !loaded && 'loading-bg'
          )}
          alt='promo'
          // onLoad={() => {
          //   setLoaded(true);
          // }}
        />
      </div>
      <div className='bg-[#F4F4F4] px-4 pt-3 md:flex md:bg-transparent md:px-0  md:pt-0'>
        <div className='text-16 text-scale-body font-normal md:rounded-lg md:bg-white md:pt-6'>
          {/* <h2 className='mb-3 font-medium'>{t('promotionDepositTitle')}</h2> */}
          <h1 className='text-scale-title mb-[16px] text-[32px] font-bold md:pl-0'>
            {t('cashbackWeeklyTitle')}
          </h1>
          <div>
            Get up to 8% cashback on your sports betting losses, capped at
            30,000 Baht every week.
          </div>
          <h3 className='text-16 pb-3 pt-6 font-bold'>Promotion Details:</h3>
          <div className='pb-6'>
            <div>
              <b>Promotion Period</b>: Long-Term (Ongoing).
            </div>
            <div>
              <b>Eligibility</b>: All Members.
            </div>
            <div>
              <b>How to claim</b>: Automatic credited to your account.
            </div>
          </div>
          <h3 className='text-main-secondary text-20 font-bold'>
            Term and Conditions:
          </h3>
          <h3 className='text-16 mb-3 mt-6 font-bold'>
            Cashback Conditions and Calculation:
          </h3>
          <ul className='list-disc pl-4'>
            <li>
              Weekly Calculation: Based on settled betting tickets from Monday
              00:00 to Sunday 23:59.
            </li>
            <li>Processing Day: Cashback is processed every Monday.</li>
            <li>Maximum Cashback: Up to 30,000 Baht per week.</li>
            <li>
              Minimum Net Loss: You must have at least 2,000 Baht in net sports
              betting losses (gains - losses) within the week to qualify.
            </li>
            <li>
              Cashback Percentage: Ranges from 5% to 8%, depending on your
              membership level.
            </li>
          </ul>
          <h3 className='text-16 my-6 font-bold'> </h3>
          <div className='md:text-14 vw:text-12 flex flex-col bg-white md:max-w-[520px] md:bg-white'>
            <div className='flex items-stretch justify-start rounded-t border border-b-0 border-[#00000026]'>
              <div className='text-main-secondary flex w-1/2 items-center justify-start border-r border-[#00000026] px-2 py-3 pl-4 text-center font-bold'>
                Member Levels
              </div>
              <div className='text-main-secondary flex w-1/2 items-center justify-start px-2 py-3 pl-4 text-left font-bold'>
                Weekly Cashback % Sports Book
              </div>
            </div>

            <div className='flex items-stretch justify-start rounded-b border border-[#00000026]'>
              <div className='flex w-1/2 items-center justify-start border-r border-[#00000026] px-2 py-3 pl-4 text-center'>
                Bronze to VVIP
              </div>
              <div className='flex w-1/2 items-center px-2 py-3 pl-5 text-center'>
                5.0 - 8.0%
              </div>
            </div>
          </div>

          <div className='flex flex-row items-center justify-center'>
            <Link href='/account/deposit'>
              <ButtonCore
                className='!primary-btn mt-6 w-[200px] md:w-[160px] md:rounded-2xl'
                label={t('joinNow')}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashbackWeekly;
