/* eslint-disable react-hooks/exhaustive-deps */

import clsx from 'clsx';
import Image from 'next/image';

import ButtonCore from '@/components/core/Button';
import Link from '@/components/core/Link';

import { useTranslation } from '@/app/i18n';

const RebatePage: React.FC<any> = async ({ params }) => {
  const { t } = await useTranslation(params?.lng, 'PromotionPage');
  // const [loaded, setLoaded] = useState(false);

  return (
    <div className='md:pr-6'>
      <div className='p-3 md:hidden'></div>
      <div className='px-3 md:rounded-none md:md:p-6 md:px-0 md:pb-0'>
        <Image
          width={1000}
          height={430}
          src='/img/promotion/rebate-banner.png'
          className={clsx(
            'w-full rounded-xl md:rounded-lg'
            // !loaded && 'loading-bg'
          )}
          alt='promo'
          // onLoad={() => {
          //   setLoaded(true);
          // }}
        />
      </div>
      <div className='bg-[#F4F4F4] px-4 pt-3 md:flex md:bg-transparent md:px-0  md:pt-0'>
        <div className='text-16 text-scale-body font-normal md:rounded-lg md:bg-white md:pr-16 md:pt-6'>
          {/* <h2 className='mb-3 font-medium'>{t('promotionDepositTitle')}</h2> */}
          <h1 className='text-scale-title mb-[16px] text-[32px] font-bold md:pl-0'>
            {t('rebateTitle')}
          </h1>
          <div>
            This Rebate Promotion is designed to reward our members with cash
            rebates based on their total Daily Stake amounts. Additionally, an
            extra Booster Rebate Bonus will be offered on your consecutive days
            of staking. The more you stake, the more you earn back!
          </div>
          <h3 className='text-16 pb-3 pt-6 font-bold'>Promotion Details:</h3>
          <div className='pb-6'>
            <div>
              <b>Promotion Period</b>: Long-Term (Ongoing).
            </div>
            <div>
              <b>Eligibility</b>: All Members.
            </div>
            <div>
              <b>How to claim</b>: Automatic credited to your account.
            </div>
          </div>
          <h3 className='text-main-secondary text-20 font-bold'>
            Term and Conditions:
          </h3>
          <h3 className='text-16 mb-3 mt-6 font-bold'>
            Rebate Structure and Calculation:
          </h3>
          <ul className='list-disc pl-4'>
            <li>All bets placed everyday between 00:00 to 23:59:59 (GMT+7).</li>
            <li>Successful eligible stakes on all products.</li>
            <li>
              On top of the daily rebate, you will receive an additional Booster
              Bonus if you stake on consecutive days.{' '}
            </li>
            <li>
              This rebate promotion cannot be combined with any other
              promotions.
            </li>
            <li>
              Total daily rebate amount is calculated based on the rebate % and
              booster % rate set for different{' '}
              <Link className='font-bold !text-[#2A72FF] underline' href='/'>
                member levels
              </Link>{' '}
              and product types as below:
            </li>
          </ul>
          <div className='md:text-14 vw:text-12 my-6 flex flex-col bg-white md:max-w-[650px] md:bg-transparent'>
            <div className='flex items-stretch justify-center rounded-t-xl border border-b-0 border-[#00000026]'>
              <div className='text-main-secondary mx-auto flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                Member Levels
              </div>
              <div className='text-main-secondary flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                Daily Rebate % Sportsbook / Lottery
              </div>
              <div className='text-main-secondary flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                Daily Rebate % Live Casino / Casino
              </div>
              <div className='text-main-secondary mx-auto flex w-1/4 items-center justify-center px-2  py-3 text-center font-bold'>
                Booster %
              </div>
            </div>

            <div className='flex items-stretch justify-center rounded-b-xl border  border-[#00000026]'>
              <div className='mx-auto flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                Bronze to VVIP
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                0.30 - 0.88%
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                0.30 - 0.88%
              </div>
              <div className='mx-auto flex w-1/4 items-center justify-center px-2 py-3 text-center'>
                3.0 - 8.8%
              </div>
            </div>
          </div>

          <h3 className='text-16 mb-3 font-bold'>Example</h3>
          <div>
            A Diamond level member staked 10,000 THB in Sportsbook and 20,000
            THB in Casino today. Daily bonus would be (10,000 x 0.7%) + (20,000
            x 0.7%) = 210 THB. If member also placed stakes yesterday, an extra
            7% booster will be added (210 x 7%) = 14.7 THB. Total rebate for
            today would be 224.7 THB.{' '}
          </div>

          {/* <p className='mt-4'>{t('exampleDeposit1')}</p> */}
          {/* <ul className='list-disc pl-4'>
            <li>{t('exampleDeposit1Detail1')}</li>
            <li>{t('exampleDeposit1Detail2')}</li>
          </ul> */}
          {/* <ul className='list-disc pl-4'>
            <li>{t('promotionCondition1')}</li>
            <li>{t('promotionCondition2')}</li>
            <li>{t('promotionCondition3')}</li>
            <li>{t('promotionCondition4')}</li>
            <li>{t('promotionCondition5')}</li>
            <li>{t('promotionCondition6')}</li>
            <li>{t('promotionCondition7')}</li>
            <li>{t('promotionCondition8')}</li>
            <li>{t('promotionCondition9')}</li>
          </ul> */}
          {/* <p className='mt-6'>{t('generalTerms')}</p>
          <ul className='list-decimal pl-4'>
            <li>{t('generalTerm1')}</li>
            <li>{t('generalTerm2')}</li>
            <li>{t('generalTerm3')}</li>
          </ul>
          <ul className='mt-1 pl-4 italic'>
            <li>{t('generalTerm4')}</li>
          </ul> */}
          <div className='flex flex-row items-center justify-center'>
            <Link href='/account/deposit'>
              <ButtonCore
                className='!primary-btn mt-6 w-[200px] md:w-[160px] md:rounded-2xl'
                label={t('joinNow')}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RebatePage;
