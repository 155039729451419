import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import ButtonCore from '@/components/core/Button';
import Link from '@/components/core/Link';

import { useTranslation } from '@/app/i18n';

const NewPayUserPage: React.FC<any> = async ({ params }) => {
  const { t } = await useTranslation(params?.lng, 'PromotionPage');

  return (
    <div className='md:pr-6'>
      <div className='p-3 md:hidden'></div>
      <div className='px-3 md:rounded-none md:md:p-6 md:px-0 md:pb-0'>
        <Image
          width={1000}
          height={430}
          src='/img/promotion/first-deposit.png'
          className={clsx('w-full rounded-xl md:rounded-lg')}
          alt='promo'
        />
      </div>
      <div className='bg-[#F4F4F4] px-4 pt-3 md:flex md:bg-transparent md:px-0  md:pt-0'>
        <div className='text-16 text-scale-body font-normal md:rounded-lg md:bg-white md:pt-6'>
          <h1 className='text-scale-title mb-[16px] text-[32px] font-bold md:pl-0'>
            {t('depositTitle')}
          </h1>
          <div>{t('firstDepositDescription')}</div>
          <h3 className='text-16 pb-3 pt-6 font-bold'>Promotion Details:</h3>
          <div className='pb-6'>
            <div>
              <b>Promotion Period</b>: Ongoing
            </div>
            <div>
              <b>Eligibility</b>: Only for new members making their first
              deposit.
            </div>
            <div>
              <b>How to claim</b>: Make deposit and meet a wagering requirement
              within a designated period.
            </div>
          </div>
          <h3 className='text-main-secondary text-20 font-bold'>
            Term and Conditions:
          </h3>
          <h3 className='text-16 mb-3 mt-6 font-bold'>
            Rebate Structure and Calculation:
          </h3>
          <ul className='list-disc pl-4'>
            <li>
              Make your deposit and PhomBet will bonus extra 100% into your
              account, up to 7,000 THB.
            </li>
            <li>
              You need to wager 20 times the total of your deposit plus bonus in
              order to qualify withdrawal of your bonus and any associated
              winnings.
            </li>
            <li>
              Complete the wagering within 15 days, or your balance resets to
              zero.
            </li>
            <li>
              You may cancel this bonus promotion anytime during participation,
              and your balance will reset to zero.
            </li>
            <li>
              This promotion cannot be combined with other promotions or events,
              such as cashback or rebate.
            </li>
          </ul>
          <h3 className='text-16 my-6 font-bold'>Example</h3>
          <div className='md:text-14 flex flex-col bg-white md:max-w-[550px] md:bg-transparent'>
            <div className='flex items-stretch justify-center rounded-t border border-b-0 border-[#00000026]'>
              <div className='text-main-secondary flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                First Deposit Amount
              </div>
              <div className='text-main-secondary flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                Extra Bonus
              </div>
              <div className='text-main-secondary flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center font-bold'>
                Turnover Required
              </div>
              <div className='text-main-secondary flex w-1/4 items-center justify-center px-2  py-3 text-center font-bold'>
                Withdraw
              </div>
            </div>
            <div className='flex items-stretch justify-center border border-b-0 border-[#00000026]'>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                100
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                100
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                4,000
              </div>
              <div className='flex w-1/4 items-center justify-center px-2 py-3   text-center'>
                200 + Winning
              </div>
            </div>
            <div className='flex items-stretch justify-center border border-b-0 border-[#00000026]'>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                1,000
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                1,000
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                4,000
              </div>
              <div className='flex w-1/4 items-center justify-center px-2 py-3   text-center'>
                2,000 + Winning
              </div>
            </div>
            <div className='flex items-stretch justify-center rounded-b border  border-[#00000026]'>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                7,000
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                7,000
              </div>
              <div className='flex w-1/4 items-center justify-center border-r border-[#00000026] px-2 py-3 text-center'>
                28,000
              </div>
              <div className='flex w-1/4 items-center justify-center px-2 py-3 text-center'>
                14,000 + Winning
              </div>
            </div>
          </div>
          <div className='mb-6 mt-3'>
            <i className='text-12'>
              To withdraw your bonus and any winnings, you'll need to achieve
              the required turnover
            </i>
          </div>
          <h3 className='text-16 mb-3 font-bold'>Turnover Calculation:</h3>
          <div>
            Turnover is calculated based on bet amounts of our various products:
          </div>
          <ul className='list-disc pl-4 pt-4'>
            <li>
              100% Contribution: Most products (except those listed below).
            </li>
            <li>70% Contribution: K-Sports.</li>
            <li>60% Contribution: Lottery.</li>
            <li>25% Contribution: Slots and Fishing Game (Tech Play).</li>
            <li>
              Excluded Games: Table Games, Live Casino, Quick Games, QTech,
              PGSoft, and Pragmatic slot games.
            </li>
          </ul>

          {/* <p className='mt-4'>{t('exampleDeposit1')}</p> */}
          {/* <ul className='list-disc pl-4'>
            <li>{t('exampleDeposit1Detail1')}</li>
            <li>{t('exampleDeposit1Detail2')}</li>
          </ul> */}
          {/* <ul className='list-disc pl-4'>
            <li>{t('promotionCondition1')}</li>
            <li>{t('promotionCondition2')}</li>
            <li>{t('promotionCondition3')}</li>
            <li>{t('promotionCondition4')}</li>
            <li>{t('promotionCondition5')}</li>
            <li>{t('promotionCondition6')}</li>
            <li>{t('promotionCondition7')}</li>
            <li>{t('promotionCondition8')}</li>
            <li>{t('promotionCondition9')}</li>
          </ul> */}
          {/* <p className='mt-6'>{t('generalTerms')}</p>
          <ul className='list-decimal pl-4'>
            <li>{t('generalTerm1')}</li>
            <li>{t('generalTerm2')}</li>
            <li>{t('generalTerm3')}</li>
          </ul>
          <ul className='mt-1 pl-4 italic'>
            <li>{t('generalTerm4')}</li>
          </ul> */}
          <div className='flex flex-row items-center justify-center'>
            <Link href='/account/deposit'>
              <ButtonCore
                className='!primary-btn mt-6 w-[200px] md:w-[160px] md:rounded-2xl'
                label={t('joinNow')}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPayUserPage;
